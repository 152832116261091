import { useParams } from "react-router-dom";
import { MenuOptions } from "../types";

type HistoryParams = {
  menuOption: MenuOptions;
};

export const useGetMenuOption = () => {
  const { menuOption: selectedOption } = useParams<HistoryParams>();
  const possibleMenuOptions = Object.values(MenuOptions);
  const isValidMenuOption = possibleMenuOptions.includes(selectedOption);

  return { selectedOption, isValidMenuOption };
};
