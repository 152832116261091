/* eslint-disable import/no-cycle */
import Flex from "app/components/Flex";
import React from "react";
import { useHistory } from "react-router-dom";
import { NavFilterButton } from "app/components/Button/NavFilterButton";
import { Routes } from "constants/routes";
import { MenuOptions } from "./types";
import { useGetMenuOption } from "./hooks";

export const Menu = () => {
  const { selectedOption } = useGetMenuOption();
  const history = useHistory();

  const checkSelectedOption = (option: MenuOptions) =>
    option === selectedOption;

  const handleSelectOption = (option: MenuOptions) => {
    history.push(`${Routes.history}/${option}`);
  };

  return (
    <Flex alignItems="center" width="100%" flexWrap="wrap" gap="8px">
      <NavFilterButton
        text="All"
        isSelected={checkSelectedOption(MenuOptions.All)}
        onClick={() => handleSelectOption(MenuOptions.All)}
      />
      <NavFilterButton
        text="Incomplete"
        isSelected={checkSelectedOption(MenuOptions.Incomplete)}
        onClick={() => handleSelectOption(MenuOptions.Incomplete)}
      />
      <NavFilterButton
        text="Complete"
        isSelected={checkSelectedOption(MenuOptions.Complete)}
        onClick={() => handleSelectOption(MenuOptions.Complete)}
      />
    </Flex>
  );
};
