import Flex from "app/components/Flex";
import BaseText from "app/components/Text";
import styled from "styled-components";

interface Props {
  isSelected?: boolean;
}

type Container = Props &
  React.HTMLProps<HTMLButtonElement> &
  React.HTMLAttributes<HTMLButtonElement>;

type Text = Props &
  React.HTMLProps<HTMLButtonElement> &
  React.HTMLAttributes<HTMLButtonElement>;

export const Container = styled(Flex).attrs<Container>(
  ({ isSelected, theme }) => ({
    backgroundColor: isSelected ? theme.colors.monochrome[8] : "transparent",
  })
)`
  padding: 8px 24px;
  height: 30px;
  border-radius: 2px;
  border: ${({ theme }) => `2px solid ${theme.colors.black}`};
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  cursor: pointer;
`;

export const Text = styled(BaseText).attrs<Text>(({ isSelected, theme }) => ({
  color: isSelected ? "white" : theme.colors.monochrome[9],
}))`
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  white-space: nowrap;
`;
