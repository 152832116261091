import React, { useEffect, useRef } from "react";
import { Container, Text } from "./styles";

type ItemProps = {
  text: string;
  isSelected?: boolean;
  onClick?: () => void;
};

export const NavFilterButton = ({ text, isSelected, onClick }: ItemProps) => {
  const ref = useRef<HTMLInputElement>();

  useEffect(() => {
    if (isSelected) {
      ref?.current?.scrollIntoView(false);
    }
  }, []);

  return (
    <Container ref={ref} isSelected={isSelected} onClick={onClick}>
      <Text isSelected={isSelected}>{text}</Text>
    </Container>
  );
};
