import {
  useClassHistoryDataQuery,
  useProgramHistoryDataQuery,
  ContentType,
  ProgramV2,
  Class,
  SortBy,
  SortOrder,
  ProgressFilter,
} from "services/graphql";

export function useHistoryData({
  orderDirection = SortOrder.Desc,
  filterBy = ProgressFilter.All,
} = {}) {
  const classesPerPage = 10;
  const classesPagination = {
    first: classesPerPage,
    contentType: ContentType.Classes,
  };
  const classesSort = {
    orderBy: SortBy.DateLastViewed,
    orderDirection,
    filterBy,
  };
  const {
    data: classHistoryQueryData,
    loading: classesHistoryLoading,
    fetchMore,
  } = useClassHistoryDataQuery({
    variables: {
      input: {
        pagination: classesPagination,
        sort: classesSort,
      },
    },
  });
  const {
    data: programHistoryQueryData,
    loading: programsHistoryLoading,
  } = useProgramHistoryDataQuery({
    variables: {
      input: {
        pagination: { first: 50, contentType: ContentType.Programs },
        sort: {
          orderBy: SortBy.DateLastViewed,
          orderDirection,
          filterBy,
        },
      },
    },
  });

  const classesHistory = classHistoryQueryData?.classHistoryData?.edges?.map(
    edge => edge.node as Class
  );
  const programsHistory = programHistoryQueryData?.programHistoryData?.edges?.map(
    edge => edge.node as ProgramV2
  );

  return {
    classesHistoryLoading,
    programsHistoryLoading,
    programsHistory,
    classesHistory,
    pageInfo: classHistoryQueryData?.classHistoryData?.pageInfo,
    fetchMoreHistory: () =>
      fetchMore({
        variables: {
          input: {
            pagination: {
              ...classesPagination,
              first: classesPerPage,
              after:
                classHistoryQueryData?.classHistoryData?.pageInfo?.endCursor,
            },
            sort: classesSort,
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const previousEntry = previousResult.classHistoryData;
          const previousEdges = previousEntry.edges;
          const newEdges = fetchMoreResult.classHistoryData.edges;

          return {
            classHistoryData: {
              ...previousEntry,
              pageInfo: {
                ...fetchMoreResult.classHistoryData.pageInfo,
              },
              edges: [...previousEdges, ...newEdges],
            },
          };
        },
      }),
  };
}
